import { UrlObject } from 'url'
import Link from 'next/link'
import * as React from 'react'
import classNames from 'classnames'
import { Icon } from '@components/Icon'

import styles from './Breadcrumbs.module.css'

export interface BreadcrumbProps {
  text: string
  href?: string | UrlObject
  as?: string | UrlObject
  onClick?: () => void
  active?: boolean
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  text,
  href,
  as,
  active,
  onClick,
}) => {
  const clickableClassNames = classNames(
    styles['breadcrumb'],
    styles['breadcrumb--clickable']
  )
  return (
    <React.Fragment>
      {href && (
        <Link
          href={href}
          as={as}
          className={clickableClassNames}
          onClick={onClick}
        >
          {text}
        </Link>
      )}
      {!href && onClick && (
        <button className={clickableClassNames} onClick={onClick}>
          {text}
        </button>
      )}
      {!href && !onClick && (
        <span
          className={classNames(
            styles['breadcrumb'],
            active && styles['breadcrumb--active']
          )}
        >
          {text}
        </span>
      )}
    </React.Fragment>
  )
}

export const Breadcrumbs: React.FC<{ path?: BreadcrumbProps[] }> = ({
  path,
}) => {
  path = path || []
  const childrenLength = path.length

  if (!childrenLength) return null

  return (
    <ul className={styles['breadcrumbs']} data-selector="fs-show">
      {path.map((childProps, index) => {
        const isLast = index == childrenLength - 1

        if (!isLast) {
          return (
            <React.Fragment key={`breadcrumb__${index}`}>
              <li>
                <Breadcrumb {...childProps} />
              </li>
              <li>
                <Icon
                  className={styles['breadcrumb-spacer']}
                  size={18}
                  name="keyboard_arrow_right"
                />
              </li>
            </React.Fragment>
          )
        }

        return (
          <li key={`breadcrumb__${index}`}>
            <Breadcrumb {...childProps} />
          </li>
        )
      })}
    </ul>
  )
}
