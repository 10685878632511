import { FormikHelpers } from 'formik'
import * as React from 'react'
import { capitalize } from 'lodash'
import { Anchor } from '@components/Anchor'
import { Card } from '@components/Card'
import { FormField } from '@components/Form'
import { Heading } from '@components/Heading'
import { Page } from '@components/Page'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { getAvatarLink } from '@lib/duffel-client'
import { mapUserToIdentifiableTraits, trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace, WorkspaceContextType } from '@lib/workspace-context'
import { signOutAndRedirect } from '@lib/security'
import {
  PasswordUpdateForm,
  UpdatePasswordFormValues,
  UpdateUserFormValues,
  UserAvatarUploadForm,
  UserUpdateForm,
} from '../forms'
import styles from './UserSettingsContainer.module.css'

/**
 * A vulnerability exists in the image processing functions in Duffel.Identity.Uploaders.Avatar.
 * This means that a specially crafted avatar image can be uploaded and can cause memory consumption to be exhausted when passed to Duffel.Identity.Users.User.update_changeset/3, due to the internal description of the file not matching the contents
 * This flag should be set to false to hide the avatar upload feature from the user settings page.
 *
 * To learn more: https://github.com/duffelhq/platform/pull/6447
 */
const SHOULD_RENDER_USER_AVATAR_UPLOAD = false

type UserActionData = UpdateUserFormValues & {
  avatar: DuffelAPI.Inputs.Avatar | null
} & {
  password: string | null
  currentPassword: string | null
}

const updateUserAction = async (
  userData: Partial<UserActionData>,
  formikActions:
    | FormikHelpers<UserActionData>
    | FormikHelpers<UpdatePasswordFormValues>,
  workspace: WorkspaceContextType
) => {
  if (userData.avatar) {
    userData.avatar = {
      filename: userData.avatar.filename,
      binary: userData.avatar.binary.split(',')[1],
    }
  }

  const { fullName, avatar, email, password, currentPassword } = userData
  const updateUserData: DuffelAPI.Inputs.UpdateUser = {
    sendMarketingEmails: false,
    fullName,
    avatar,
    email,
    password,
    currentPassword,
  }

  const result = await workspace.duffelClient.Identity.updateSelf(
    updateUserData
  )

  if (result.errors || !result.data) {
    result.errors?.map(({ message }) => {
      // If the user reuses a password the message from the API will say something like
      // "Field 'password' new password cannot be a previously used password"
      // It's actually quite hard to remove the "Field 'password" bit on the backend, so doing it here instead for a nicer UX.
      const errorMessage = capitalize(message.replace("Field 'password' ", ''))
      if (!userData.avatar) {
        trackEvent('dashboard_user_preferences_save_changes_alert_displayed', {
          email: email!,
          alert_message: errorMessage,
          event_type: 'alert',
        })
      }
      workspace.addToast({
        message: errorMessage,
        intent: 'warning',
      })
    })
    formikActions.setSubmitting(false)
    formikActions.resetForm()
    return
  }

  formikActions.setSubmitting(false)
  if (userData.password) {
    // if the password changes, the token we are using will not be valid anymore, so sign out:
    signOutAndRedirect(undefined, undefined, undefined, 'password_reset')
  } else {
    workspace.updateUser(result.data)
    if (!userData.avatar) {
      trackEvent(
        'dashboard_user_preferences_save_changes_confirmed',
        {
          email: result.data.email,
          full_name: result.data.fullName,
          event_type: 'api',
        },
        mapUserToIdentifiableTraits(result.data)
      )
    }

    workspace.addToast({
      message: `Success! ${
        userData.avatar ? 'Avatar' : 'Account settings'
      } updated!`,
      intent: 'success',
    })
  }
}

export const UserSettingsContainer: React.FC = () => {
  const workspace = useWorkspace()

  const { user } = workspace
  if (!user) return null

  return (
    <Page.Root maxWidth="medium">
      <Page.Header title="My account" />
      <VSpace space={40}>
        <VSpace space={16}>
          <Heading h3 asElement="h2" data-selector="fs-show">
            Information
          </Heading>

          {SHOULD_RENDER_USER_AVATAR_UPLOAD && (
            <div className={styles['flex-row']}>
              <FormField
                label="Avatar"
                hint="Click on the avatar to upload a custom one from your files. The file size limit is 1MB."
              />
              <div>
                <UserAvatarUploadForm
                  defaultAvatarLink={getAvatarLink(user!)}
                  onSubmit={({ avatar }, formikActions) => {
                    updateUserAction(
                      { avatar: avatar as DuffelAPI.Inputs.Avatar | null },
                      formikActions as FormikHelpers<UserActionData>,
                      workspace
                    )
                  }}
                />
              </div>
            </div>
          )}

          <UserUpdateForm
            user={user}
            onSubmit={async (values, formikActions) => {
              trackEvent(
                'dashboard_user_preferences_save_changes_button_clicked',
                {
                  event_type: 'interaction',
                  email: values.email,
                  full_name: values.fullName,
                }
              )
              updateUserAction(
                values,
                formikActions as FormikHelpers<UserActionData>,
                workspace
              )
            }}
          />
        </VSpace>

        <hr className={styles['divider']} />

        <VSpace space={16}>
          <Heading h3 asElement="h2" data-selector="fs-show">
            Password
          </Heading>

          <PasswordUpdateForm
            onSubmit={async (values, formikActions) => {
              trackEvent(
                'dashboard_user_preferences_password_save_changes_button_clicked',
                {
                  event_type: 'interaction',
                }
              )
              formikActions.setSubmitting(true)

              await updateUserAction(
                {
                  password: values.passwordNew,
                  currentPassword: values.passwordCurrent,
                },
                formikActions as FormikHelpers<UpdatePasswordFormValues>,
                workspace
              )
              formikActions.resetForm()
              formikActions.setSubmitting(false)
            }}
          />
        </VSpace>

        <hr className={styles['divider']} />

        <Card className={styles['delete-account-card']} data-selector="fs-show">
          <VSpace space={8}>
            <Heading h5 asElement="h3" data-selector="fs-show">
              Delete account
            </Heading>
            <VSpace space={16}>
              <Text className={styles['danger-text']} color="grey-600">
                Once you delete your account, you will no longer have access to
                Duffel Apps and API, all pending organization invitations will
                be revoked as well as organization memberships.
              </Text>
              <Text color="grey-600">
                If you'd like to delete your account, please{' '}
                <Anchor onClick={() => workspace.openSupportModal()}>
                  contact support
                </Anchor>
                .
              </Text>
            </VSpace>
          </VSpace>
        </Card>
      </VSpace>
    </Page.Root>
  )
}
