import classNames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { Spinner } from '@components/Spinner'
import { DuffelAPI } from '@lib/types'

interface AvatarUploadProps {
  loading?: boolean
  onSelectFileClick?: () => void
  onChange: (avatar: DuffelAPI.Inputs.Avatar | null) => void
  avatar: string
}

const resetFileInput = () => {
  const fileInput =
    document?.querySelector<HTMLInputElement>('input#avatar-file')
  if (!fileInput) return
  else fileInput.value = ''
}

const extractImageData = async (file, cb) => {
  if (!file) return null
  const reader = new FileReader()

  reader.onloadend = (event) => {
    if (event.loaded == event.total)
      cb({
        filename: file.name,
        binary: reader.result as string,
      })
  }

  reader.readAsDataURL(file)
}

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  avatar,
  loading,
  onChange,
  onSelectFileClick,
}) => {
  const fileInput = React.useRef<HTMLInputElement>(null)

  return (
    <React.Fragment>
      <input
        ref={fileInput}
        id="avatar-file"
        className="hidden"
        name="file"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={async (event) => {
          if (!event || !event.currentTarget || !event.currentTarget.files) {
            console.warn('event.currentTarget.files is missing')
            return
          }
          const file: File = event.currentTarget.files[0]
          extractImageData(file, onChange)
        }}
      />
      <label className="hidden" htmlFor="avatar-file">
        Avatar upload
      </label>
      <button
        type="button"
        className={classNames('button-upload', {
          'remove-avatar': !!avatar,
          loading,
        })}
        style={{ backgroundImage: avatar ? `url(${avatar})` : '' }}
        onClick={() => {
          if (avatar) onChange(null)
          else {
            onSelectFileClick && onSelectFileClick()
            resetFileInput()
            fileInput.current?.click()
          }
        }}
        disabled={loading}
      >
        {loading && (
          <div className="spinner-wrapper">
            <Spinner size="small" />
          </div>
        )}
        {!loading && <Icon name={avatar ? 'close' : 'add'} />}
      </button>

      <style jsx>{`
        .hidden {
          display: none;
        }

        .button-upload {
          cursor: pointer;
          color: var(--white);
          width: 48px;
          height: 48px;
          background: white;
          border: 1px solid rgba(26, 28, 35, 0.2);
          border-radius: var(--border-radius-4);
          background-position: center;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all var(--transitions-default);
        }

        .remove-avatar {
          border: none;
          background-color: var(--grey-400);
        }

        .button-upload:hover {
          border-color: var(--purple-500);
        }

        .button-upload:focus {
          outline: none;
          box-shadow: var(--shadow-focus);
        }

        .button-upload:active {
          box-shadow: 0 0 0 3px var(--purple-500);
        }

        .remove-avatar:after,
        .loading:after {
          content: '';
          opacity: 0;
          transition: opacity var(--transitions-default);
          width: 48px;
          height: 48px;
          border-radius: var(--border-radius-4);
          position: absolute;
          background: rgba(26, 28, 35, 0.75);
        }

        .loading:after {
          opacity: 1;
          background: rgba(26, 28, 35, 0.6);
        }

        .spinner-wrapper {
          z-index: 1;
        }

        .remove-avatar:hover:after,
        .remove-avatar:focus:after {
          opacity: 1;
        }

        .button-upload :global(.ff-icon) {
          z-index: 1;
          color: var(--grey-700);
          transition: opacity var(--transitions-default);
        }

        .button-upload:hover :global(.ff-icon) {
          opacity: 1;
          color: var(--purple-500);
        }

        .button-upload:focus :global(.ff-icon) {
          opacity: 1;
        }

        .remove-avatar :global(.ff-icon) {
          opacity: 0;
          color: var(--white);
        }
        .remove-avatar:hover :global(.ff-icon) {
          opacity: 1;
          color: var(--white);
        }
        .remove-avatar:focus :global(.ff-icon) {
          opacity: 1;
          color: var(--white);
        }
      `}</style>
    </React.Fragment>
  )
}
