import * as React from 'react'
import classNames from 'classnames'
import { AirlineLogo } from '@components/AirlineLogo'
import { BreadcrumbProps, Breadcrumbs } from '@components/Breadcrumbs'
import { Heading } from '@components/Heading'
import { HSpace } from '@components/HSpace'
import { VSpace } from '@components/VSpace'
import { PageMaxWidth } from '@lib/styles'
import styles from './Page.module.css'

interface RootProps {
  /**
   * Sets the max width of the page content
   *
   * xsmall: `480px`
   * small : `640px`
   * medium : `800px`
   * large : `1280px`
   * full : `100%`
   */
  maxWidth: PageMaxWidth

  /**
   * Hides overflow. Can be set to `true` to implement nested scroll elements.
   *
   * @default
   * true
   */
  hideOverflow?: boolean
}

export const Root: React.FC<React.PropsWithChildren<RootProps>> = ({
  children,
  maxWidth,
  hideOverflow = false,
  ...props
}) => (
  <div
    className={classNames(
      styles['page'],
      hideOverflow && styles['page--hide-overflow']
    )}
    {...props}
  >
    <div
      className={classNames(
        styles['page__inner'],
        styles[`page__inner--${maxWidth}`],
        hideOverflow && styles['page__inner--hide-overflow']
      )}
    >
      {children}
    </div>
  </div>
)

interface HeaderProps {
  breadcrumbs?: BreadcrumbProps[]
  showAirlineLogo?: { iataCode: string; airlineName: string }
  title?: string | React.ReactNode
  afterTitle?: React.ReactNode
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  breadcrumbs,
  children,
  showAirlineLogo,
  title,
  afterTitle,
}) => {
  const shouldRenderMain =
    typeof children !== 'undefined' || typeof title !== 'undefined'

  return (
    <VSpace space={8} className={styles['header']}>
      {breadcrumbs && <Breadcrumbs path={breadcrumbs} />}

      {shouldRenderMain && (
        <div className={styles['header__main']}>
          <HSpace alignCenter space={16}>
            {showAirlineLogo && (
              <AirlineLogo
                iataCode={showAirlineLogo.iataCode}
                name={showAirlineLogo.airlineName}
                size={32}
              />
            )}

            {typeof title === 'string' ? (
              <Heading h2 asElement="h1" fontWeight="medium">
                {title}
              </Heading>
            ) : (
              <div>{title}</div>
            )}

            {afterTitle}
          </HSpace>

          {children && (
            <HSpace space={8} alignCenter>
              {children}
            </HSpace>
          )}
        </div>
      )}
    </VSpace>
  )
}

export default Root
