import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { object, string } from 'yup'
import { Form, FormikInput } from '@components/Form'
import { VSpace } from '@components/VSpace'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { DuffelAPI } from '@lib/types'
import { validEmailAddressRequired } from '@lib/form-validation'
import { UnconfirmedEmailBanner } from '../components/UnconfirmedEmailBanner'
import { PasswordModal } from './PasswordModal'
import styles from './UserUpdateForm.module.css'

const formValidationSchema = object().shape({
  email: validEmailAddressRequired(),
  fullName: string().required('A name is required'),
})

export type UpdateUserFormValues = Pick<
  DuffelAPI.Types.User,
  'email' | 'fullName'
> & { password: string; currentPassword?: string }

interface UserUpdateFormProps {
  user: Pick<DuffelAPI.Types.User, 'email' | 'fullName' | 'unconfirmedEmail'>
  onSubmit: (
    values: UpdateUserFormValues,
    formikActions: FormikHelpers<UpdateUserFormValues>
  ) => void
}

export const UserUpdateForm: React.FunctionComponent<UserUpdateFormProps> = ({
  user,
  onSubmit,
}) => {
  const [isPasswordModalOpen, setPasswordModalOpen] = React.useState(false)

  return (
    <Formik
      initialValues={{ ...user, password: '' }}
      onSubmit={(values, formikActions) => {
        if (values.email !== user.email || values.fullName !== user.fullName) {
          setPasswordModalOpen(true)
        } else {
          onSubmit(values, formikActions as FormikHelpers<UpdateUserFormValues>)
        }
      }}
      validationSchema={formValidationSchema}
    >
      {(formikProps) => {
        const { handleSubmit, setSubmitting, values } = formikProps
        const updateEnabled =
          values.fullName !== user.fullName || values.email !== user.email

        return (
          <Form onSubmit={handleSubmit}>
            <VSpace space={24} className="user-update-form">
              <FormikInput name="fullName" label="Full name" />
              <FormikInput name="email" label="Email" type="email" />
              <UnconfirmedEmailBanner />

              <FormikSubmitButton
                className={styles['save-changes-button']}
                text="Save changes"
                fill={false}
                large={false}
                disabled={!updateEnabled}
                data-selector="fs-show"
              />
            </VSpace>
            {isPasswordModalOpen && (
              <PasswordModal
                onClose={() => {
                  setPasswordModalOpen(false)
                  setSubmitting(false)
                }}
                onSuccessfulPasswordCheck={(password) =>
                  onSubmit(
                    { currentPassword: password, ...values },
                    formikProps
                  )
                }
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
