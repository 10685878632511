import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { AvatarUpload } from '@components/AvatarUpload'
import { DuffelAPI } from '@lib/types'

interface UserAvatarUploadFormValues {
  avatar: DuffelAPI.Inputs.Avatar | string | null
}

const getAvatarUrl = (
  fromFormValue: UserAvatarUploadFormValues['avatar']
): string => {
  if (fromFormValue === null || fromFormValue === undefined) return ''
  if (typeof fromFormValue === 'string') return fromFormValue
  return fromFormValue.binary
}

interface AvatarUploadFormProps {
  defaultAvatarLink: string
  onSubmit: (
    values: UserAvatarUploadFormValues,
    formikActions: FormikHelpers<UserAvatarUploadFormValues>
  ) => void
}

export const UserAvatarUploadForm: React.FC<AvatarUploadFormProps> = ({
  defaultAvatarLink,
  onSubmit,
}) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={
        {
          avatar: defaultAvatarLink,
        } as UserAvatarUploadFormValues
      }
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue, submitForm }) => (
        <form onSubmit={handleSubmit}>
          <AvatarUpload
            avatar={getAvatarUrl(values.avatar)}
            onChange={(avatar) => {
              setFieldValue('avatar', avatar)
              submitForm()
            }}
            loading={isSubmitting}
          />
        </form>
      )}
    </Formik>
  )
}
