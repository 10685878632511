import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as React from 'react'
import { object, string } from 'yup'
import { Form, passwordValidationSchema } from '@components/Form'
import { FormikPassword } from '@components/Form/FormikPassword'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { VSpace } from '@components/VSpace'

const formValidationSchema = object().shape({
  passwordCurrent: string().required('The current password is required'),
  passwordNew: passwordValidationSchema,
})

export type UpdatePasswordFormValues = {
  passwordCurrent: string
  passwordNew: string
}

interface PasswordUpdateFormProps {
  onSubmit: (
    values: UpdatePasswordFormValues,
    formikActions: FormikHelpers<UpdatePasswordFormValues>
  ) => void
}

export const PasswordUpdateForm: React.FunctionComponent<
  PasswordUpdateFormProps
> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{ passwordCurrent: '', passwordNew: '' }}
      onSubmit={onSubmit}
      validationSchema={formValidationSchema}
    >
      {(formikProps: FormikProps<UpdatePasswordFormValues>) => {
        const { handleSubmit, values } = formikProps
        const updateEnabled = values.passwordCurrent && values.passwordNew

        return (
          <Form onSubmit={handleSubmit}>
            <VSpace space={24}>
              <FormikPassword label="Current password" name="passwordCurrent" />
              <FormikPassword
                label="New password"
                name="passwordNew"
                showRequirements
                maxLength={100}
              />
              <FormikSubmitButton
                className="save-changes-button"
                text="Save changes"
                large={false}
                fill={false}
                disabled={!updateEnabled}
                data-selector="fs-show"
              />
            </VSpace>
            <style jsx>{`
              :global(button .save-changes-button) {
                display: none;
              }
            `}</style>
          </Form>
        )
      }}
    </Formik>
  )
}
