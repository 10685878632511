import { createDuffelClient } from '@lib/duffel-client'
import { APIResponseError, APIResponseMeta, DuffelProxy } from '@lib/types'
import { SignInValues } from '../types'

export interface FormSubmissionCallback<T = any> {
  onSuccess: (value: T) => void
  onFailure: (errors: APIResponseError[], meta?: APIResponseMeta) => void
}

export const onSignInSubmission = async (
  values: SignInValues,
  { onSuccess, onFailure }: FormSubmissionCallback<DuffelProxy.Types.Self>
) => {
  const result = await createDuffelClient().Identity.signIn({
    ...values,
    email: values.email.toLowerCase(),
  })

  if (result.errors) return onFailure(result.errors, result.meta)
  if (!result.data) return onFailure([], result.meta)

  onSuccess(result.data)
}
