import { useUnleashContext } from '@unleash/proxy-client-react'
import { FormikHelpers } from 'formik'
import { NextRouter, useRouter } from 'next/router'
import * as React from 'react'
import { VSpace } from '@components/VSpace'
import { SUBMISSION_FAILURE } from '@lib/constants'
import { isBrowser } from '@lib/env'
import { isSafeRedirect } from '@lib/helpers'
import { decodeJWT } from '@lib/jwt'
import { ordersIndexPathObject } from '@lib/paths'
import { setSentryUser } from '@lib/sentry'
import { mapSelfToIdentifiableTraits, trackEvent } from '@lib/tracking'
import { APIResponseError, DuffelProxy } from '@lib/types'
import { getUnleashContextObject } from '@lib/unleash'
import { useWorkspace } from '@lib/workspace-context'
import { onSignInSubmission } from '../actions'
import { SignInForm } from '../forms/SignInForm'
import { SignInValues } from '../types'

const getNextPagePath = ({ query }: NextRouter): string | null => {
  if (!query || !query.next || typeof query.next !== 'string') return null

  const nextPagePath = query.next
  const origin = isBrowser ? location.origin : ''

  if (isSafeRedirect(nextPagePath, origin)) {
    return new URL(decodeURIComponent(nextPagePath), origin).pathname
  }

  return null
}

const redirect = async (
  forSelf: DuffelProxy.Types.Self,
  router: NextRouter
) => {
  let redirectPath = ''
  const nextPagePath = getNextPagePath(router)

  if (nextPagePath) {
    redirectPath = nextPagePath
  } else if (forSelf.emailConfirmedAt === null) {
    redirectPath = '/confirm-email'
  } else if (forSelf.organisationSlugs.length === 0) {
    redirectPath = '/create-team'
  } else if (forSelf.organisationSlugs.length === 1) {
    redirectPath = ordersIndexPathObject(
      forSelf.organisationSlugs[0],
      forSelf.organisationsBySlug[forSelf.organisationSlugs[0]].isVerified
    ).as
  } else {
    redirectPath = '/'
  }

  await router.push(redirectPath)
}

const onSubmitFailure = (
  email: string,
  errors: APIResponseError[],
  { setSubmitting, setFieldError, setStatus }: FormikHelpers<SignInValues>,
  onRequirePasswordReset: (email: string) => void
) => {
  for (const { code, message, source } of errors) {
    if (source) {
      const { pointer } = source
      setFieldError(pointer.replace('/', ''), message)
      trackEvent('dashboard_signin_alert_displayed', {
        alert_message: message,
        event_type: 'alert',
      })
    } else {
      if (code === FORCE_PASSWORD_RESET_ERROR_CODE) {
        return onRequirePasswordReset(email)
      }
      setStatus({
        code: SUBMISSION_FAILURE,
        message,
      })
      trackEvent('dashboard_signin_alert_displayed', {
        alert_message: message,
        event_type: 'alert',
      })
    }
  }

  setSubmitting(false)
}

interface SignInFormContainerProps {
  onRequirePasswordReset: (forEmail: string) => void
}
const FORCE_PASSWORD_RESET_ERROR_CODE = 'password_reset_required'

export const SignInFormContainer: React.FC<SignInFormContainerProps> = ({
  onRequirePasswordReset,
}) => {
  const router = useRouter()
  const { updateUser, setPermissions } = useWorkspace()
  const updateUnleashContext = useUnleashContext()

  const updateContext = (self: Partial<DuffelProxy.Types.Self>) => {
    return new Promise((resolve, _reject) => {
      updateUser(self)
      updateUnleashContext(
        getUnleashContextObject(self as DuffelProxy.Types.Self)
      )
      setPermissions(decodeJWT(document.cookie)!)
      resolve(true)
    })
  }

  const onSubmit = (
    values: SignInValues,
    formikActions: FormikHelpers<SignInValues>
  ) => {
    trackEvent('dashboard_signin_button_clicked', {
      email: values.email,
      event_type: 'interaction',
    })
    onSignInSubmission(values, {
      onSuccess: (self) => onSubmitSuccess(self),
      onFailure: (errors) =>
        onSubmitFailure(
          values.email,
          errors,
          formikActions,
          onRequirePasswordReset
        ),
    })
  }

  const onSubmitSuccess = (data: DuffelProxy.Types.Self) => {
    setSentryUser(data.id)
    trackEvent(
      'signed_in',
      { event_type: 'api' },
      mapSelfToIdentifiableTraits(data)
    )

    Promise.all([updateContext(data)]).finally(() => {
      redirect(data, router)
    })
  }

  return (
    <VSpace space={16}>
      <SignInForm onSubmit={onSubmit} />
    </VSpace>
  )
}
