import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { object, string } from 'yup'
import { Button } from '@components/Button'
import { Form, FormikPassword } from '@components/Form'
import { Modal, ModalProps } from '@components/Modal'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'

export interface PasswordModalProps extends ModalProps {
  onSuccessfulPasswordCheck: (password: string) => void
}

export interface PasswordFormValues {
  password: string
}

const formValidationSchema = object().shape({
  passwordCurrent: string().required('The password is required'),
})

export const PasswordModal: React.FC<PasswordModalProps> = ({
  onSuccessfulPasswordCheck,
  ...modalProps
}) => {
  const { user, addToast, duffelClient } = useWorkspace()

  const submit = async (values: PasswordFormValues) => {
    if (user) {
      const signInResult = await duffelClient.Identity.signIn({
        email: user.email,
        password: values.password,
      })

      if (signInResult.errors || !signInResult.data) {
        const message = "The password you entered doesn't match our records."
        trackEvent('dashboard_user_preferences_save_changes_alert_displayed', {
          email: user.email,
          alert_message: message,
          event_type: 'alert',
        })
        addToast({
          message: message,
          intent: 'warning',
          closeAfterTimeout: false,
        })
        return
      }

      onSuccessfulPasswordCheck(values.password)
      modalProps.onClose()
    }
  }

  return (
    <Modal {...modalProps} width={480}>
      <Formik
        initialValues={{ password: '' }}
        onSubmit={submit}
        validationSchema={formValidationSchema}
      >
        {(formikProps: FormikProps<PasswordFormValues>) => {
          const { handleSubmit, values } = formikProps

          return (
            <Form onSubmit={handleSubmit}>
              <VSpace space={32}>
                <VSpace space={16}>
                  <Text fontSize="H2" data-selector="fs-show">
                    Confirm your password
                  </Text>
                  <Text fontSize="C1" color="grey-700" data-selector="fs-show">
                    Please confirm your password.
                  </Text>
                </VSpace>
                <div className="form-field-container">
                  <FormikPassword
                    name="password"
                    autoComplete="off"
                    optionalField
                  />
                </div>

                <VSpace space={8}>
                  <FormikSubmitButton
                    large
                    fill
                    text="Confirm change"
                    disabled={!values.password}
                    onClick={() => submit(values)}
                  />
                  <Button
                    large
                    fill
                    onClick={modalProps.onClose}
                    text="Back"
                    intent="MUTED"
                  />
                </VSpace>
              </VSpace>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
