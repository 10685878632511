import * as React from 'react'
import { Anchor } from '@components/Anchor'
import { Callout } from '@components/Callout'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'

export const UnconfirmedEmailBanner: React.FC = () => {
  const [state, setState] = React.useState<'idle' | 'confirmation-sent'>('idle')
  const { user, addToast, duffelClient } = useWorkspace()

  if (!user) return null
  if (user.emailConfirmedAt && !user.unconfirmedEmail) return null

  if (state === 'confirmation-sent') {
    return <Callout iconName="check">Confirmation email sent</Callout>
  }

  return (
    <Callout iconName="warning" intent="warning">
      To complete your email change, please verify your email with the link we
      sent to <b>{user.unconfirmedEmail || user.email}</b>. Didn't receive an
      email?{' '}
      <Anchor
        className="confirmation-link"
        href="#"
        onClick={async (event) => {
          event.preventDefault()
          trackEvent(
            'dashboard_user_preferences_resend_verification_email_link_clicked',
            {
              event_type: 'interaction',
              email: user.email,
            }
          )
          await duffelClient.Identity.requestEmailConfirmation()
          addToast({
            message: `Email sent.`,
            intent: 'success',
          })
          setState('confirmation-sent')
        }}
        data-selector="fs-show"
      >
        Resend confirmation email.
      </Anchor>
      <style jsx>{`
        :global(.confirmation-link) {
          color: var(--black) !important;
          font-weight: bold;
        }
      `}</style>
    </Callout>
  )
}
