import { Formik, FormikHelpers, FormikProps } from 'formik'
import { Component } from 'react'
import { object, string } from 'yup'
import { Form, FormikInput } from '@components/Form'
import { FormikPassword } from '@components/Form/FormikPassword'
import { Notice } from '@components/Notice'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { SUBMISSION_FAILURE } from '@lib/constants'
import { validEmailAddressRequired } from '@lib/form-validation'
import { SignInValues } from '../types'

interface SignInFormProps {
  onSubmit: (
    data: SignInValues,
    formikActions: FormikHelpers<SignInValues>
  ) => void
}

export class SignInForm extends Component<SignInFormProps> {
  initialValues = { email: '', password: '' }

  validationSchema = object().shape({
    email: validEmailAddressRequired(),
    password: string().required('Password is required'),
  })

  renderForm = (formikProps: FormikProps<SignInValues>) => {
    const { handleSubmit, status } = formikProps

    const hasFailed = status && status.code === SUBMISSION_FAILURE

    return (
      <Form onSubmit={handleSubmit} method="post">
        <div className="form-wrapper">
          {hasFailed && (
            <div className="callout-wrapper">
              <Notice intent="severe" title="Sign in failed">
                {status.message}
              </Notice>
            </div>
          )}

          <div className="form-element-container">
            <FormikInput
              tabIndex={0}
              label="Email address"
              name="email"
              type="email"
              placeholder="Enter your email address…"
              autoComplete="username"
              optionalField
            />
          </div>

          <div className="form-element-container">
            <FormikPassword
              tabIndex={0}
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password…"
              autoComplete="off"
              optionalField
            />
          </div>

          <div className="button-container">
            <FormikSubmitButton
              className="sign-in-button"
              text="Sign in"
              large={false}
              id="submit"
              data-selector="fs-show"
            />
          </div>
        </div>

        <style jsx>{`
          .form-wrapper {
            position: relative;
            padding: 32px;
            background: var(--white);
            border-radius: var(--border-radius-4);
            margin-bottom: 16px;
          }

          .form-element-container {
            margin-bottom: var(--space-16);
          }

          /* combined with the form-element-container's bottom padding, this will result in 24px space between the button and the password stamp */
          .button-container {
            padding-top: var(--space-16);
          }

          .callout-wrapper {
            margin-bottom: var(--space-24);
          }

          // This button variant is styled according to brand guidelines
          :global(.sign-in-button) {
            --BUTTON-FONT-SIZE: 1rem;
            --BUTTON-ICON-SIZE: 16px;
            padding: 0 var(--space-16);
            font-weight: unset;
            height: 40px;
          }
        `}</style>
      </Form>
    )
  }

  render() {
    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.props.onSubmit}
      >
        {this.renderForm}
      </Formik>
    )
  }
}
